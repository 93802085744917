import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormsService {

  simpleString(control: FormControl): ValidationErrors | null {
    const numRExp = /^[-_:+,.\sA-Za-zÀ-ÖØ-öø-ÿ0-9]+$/i;
    if (!numRExp.test(control.value) && control.value !== '') {
      return {notSimplestring: true};
    }
    return null;
  }

  simpleText(control: FormControl): ValidationErrors | null {
    const numRExp = /^[^<>%$]*$/;
    if (!numRExp.test(control.value) && control.value !== '') {
      return {notSimpleText: true};
    }
    return null;
  }

  isPhone(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const numRExp = /^\+?[\s\d \-\(\)]+$/;
    if (!numRExp.test(control.value) && control.value !== '') {
      return {notPhone: true};
    }
    return null;
  }


  mailFormat(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const mex =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/gi;
    if (control.value !== '' && (control.value.length <= 5 || !mex.test(control.value))) {
      return {incorrectMailFormat: true};
    }
    return null;
  }



  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): ValidationErrors | null => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];
      if (password.value === confirmPassword.value) {
        return null;
      } else {
        return {mismatchedPasswords: true};
      }
    };
  }


  isNIF(control: FormControl): ValidationErrors | null{
    const v = control.value;
    if (!control.value) {
      return null;
    }
    if (v !== '') {
      const numRExpCIF = /^[ABCDEFGHKLMNPQS][0-9]{7}([a-z]|[0-9])$/i;
      const numRExpNIF = /^[XYZ]?\d{5,8}[A-Z]$/i;
      if (numRExpCIF.test(v)) {
        let par = 0;
        let non = 0;
        for (let zz = 2; zz < 8; zz += 2) {
          par += (+v.charAt(zz));
        }
        for (let zz = 1; zz < 9; zz += 2) {
          let nn = 2 * (+v.charAt(zz));
          if (nn > 9) {
            nn = 1 + (nn - 10);
          }
          non += nn;
        }
        const parcial = par + non;
        let nControl = (10 - ( parcial % 10));
        if (nControl === 10) {
          nControl = 0;
        }
        if (nControl === +v.charAt(8)) {
          return null;
        } else {
          return {notValidNIF: true};
        }
      }
      else if (numRExpNIF.test(v)) {
        let n = v.substring(0, v.length - 1);
        n = n.replace('X', 0);
        n = n.replace('Y', 1);
        n = n.replace('Z', 2);
        n %= 23;
        const letra = 'TRWAGMYFPDXBNJZSQVHLCKET'.substring(n, n + 1);
        if (letra !== v.substring(v.length - 1).toUpperCase()){
          return {notValidNIF: true};
        } else { return null; }
      }
      return {notValidNIF: true};
    }
    return null;
  }


  isIBAN(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const numRExp = /^([a-zA-Z]{2})\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})$/i;
    if (!numRExp.test(control.value)) {
      return {notIBAN: true};
    }
    return null;
  }



  checkValidity(f): FormGroup {
    Object.keys(f.controls).forEach(key => {
      f.get(key).markAsTouched();
    });
    return f;
  }

  getInvalid(formToInvestigate: FormGroup|FormArray): string[] {
      const invalidControls: string[] = [];
      const recursiveFunc = (form: FormGroup|FormArray) => {
        Object.keys(form.controls).forEach(field => {
          const control = form.get(field);
          if (control.invalid) { invalidControls.push(' ' + field); }
          if (control instanceof FormGroup) {
            recursiveFunc(control);
          } else if (control instanceof FormArray) {
            recursiveFunc(control);
          }
        });
      };
      recursiveFunc(formToInvestigate);
      return invalidControls;
  }


  removeSpaces(control: any, value: string) {
    // console.log(typeof control);
    control.setValue(value.replace(/\s/gi, ''));
  }

}
