import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RolesService} from '@services';


@Injectable({
  providedIn: 'root',
})
export class TeamsConfigGuard {

  constructor(private router: Router) {}

  canActivate() {
    if (RolesService.isADM || RolesService.isPartnerAdm) {
      return true;
    }
    this.router.navigate(['/horus/panel-de-control']).then(() => {
      console.log('Not Allowed');
    });
    return false;
  }
}
