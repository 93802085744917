import {enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {HorusComponent} from './app/horus.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RoutesInterceptor} from './app/shared/interceptors/routes.interceptor';
import {provideRouter, RouterModule, withViewTransitions} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeEs from '@angular/common/locales/es';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ServiceWorkerModule} from '@angular/service-worker';
import {routes} from './routes';
registerLocaleData(localeEs, 'es');

console.log(`
  /$$   /$$                                                /$$$$$$
| $$  | $$                                               /$$__  $$
| $$  | $$  /$$$$$$   /$$$$$$  /$$   /$$  /$$$$$$$      |__/  \\ $$
| $$$$$$$$ /$$__  $$ /$$__  $$| $$  | $$ /$$_____/        /$$$$$$/
| $$__  $$| $$  \\ $$| $$  \\__/| $$  | $$|  $$$$$$        /$$____/
| $$  | $$| $$  | $$| $$      | $$  | $$ \\____  $$      | $$
| $$  | $$|  $$$$$$/| $$      |  $$$$$$/ /$$$$$$$/      | $$$$$$$$
|__/  |__/ \\______/ |__/       \\______/ |_______/       |________/
  `);

if (environment.production) {
  enableProdMode();
  console.log = () => {
  };
}
bootstrapApplication(HorusComponent, {
  providers: [
    DatePipe, CurrencyPipe, DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RoutesInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    provideRouter(
      routes,
      withViewTransitions()
    ),
    importProvidersFrom(
      HttpClientModule,
      BrowserAnimationsModule,
      CommonModule,
      RouterModule,
      GoogleTagManagerModule.forRoot({
        id: 'GTM-TZJDP5T'
      }),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000'
      }),
    )
  ]
})
  .catch(err => console.error(err));
