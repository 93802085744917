import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService, ErrorHandlerService} from '@services';

@Injectable()
export class RoutesInterceptor {

  constructor(private router: Router,
              private errorHandlerService: ErrorHandlerService,
              private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error?.name === 'AuthenticationError') {
            this.authService.logout();
            this.router.navigate(['/']).then(() => {
              this.errorHandlerService.openDialog(error);
            });
            return throwError(() => new Error(error.error));

          } else {
            console.log('ERROR: ', error.error);
            this.errorHandlerService.openDialog(error);
            return throwError(() => new Error(error.error));
          }
        })
      );
  }

}

/*

import {Injectable} from '@angular/core';
import {ErrorHandlerService} from '../services/error-handler.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  showNoError: any;

  constructor(public errorHandlerService: ErrorHandlerService) {
    this.showNoError = [
      '/agencias/login/'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          let showError: boolean;
          for (const u of this.showNoError) {
            showError = (request.url.indexOf(u) < 0);
          }
          if (showError) {
            this.errorHandlerService.openDialog(error);
          }
          return throwError(error);
        })
      );
  }
}
*/
