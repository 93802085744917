import {Injectable} from '@angular/core';
import {SignalsManager} from '@utilities';
import {AppSignalsState} from '../interfaces/signals.interfaces';

export const emptySignals: AppSignalsState = {
  ['mapSignal']: {
    lat: 39.450542,
    lng: -0.337532,
    zoom: 16,
    municipio: 'València',
    cp: '46024',
    region: 'Comunitat Valenciana',
    api: 'AIzaSyCUex4WWK-xikmy5aWCOxZX6DHml5QPQrc',
    direccion: 'Carrer d\'Adolfo de Azcarraga (Escriptor), 8'
  },
  ['checkSignal']: {
    dossier: '',
    title: '',
    dossier_step: 'prospecto',
    checklist: 'proceso_alta_anticipada',
    progress: {}
  },
  ['dossierSignal']: {
    name: ''
  },
  ['offerSignal']: {},
  ['bankSummarySignal']: {},
  ['summarySignal']: {
    current_step_slug: 'en-proceso',
    offer: null,
    checks: []
  }
};


@Injectable({
  providedIn: 'root',
})
export class SignalsService {
  appSignalState = new SignalsManager<any>(emptySignals);

}

