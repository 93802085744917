import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'horus-main',
  templateUrl: './horus.html',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
  ]
})
export class HorusComponent {
  title = 'horus';
  environmentHorus = environment.code;

  private unsubscribe$ = new Subject<boolean>();
  constructor(private gtmService: GoogleTagManagerService,
              private router: Router) {
    this.router.events.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.googleTracking(event.url);
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {}
    });

    this.googleTracking(this.router.url);
  }


  private googleTracking(page: string){
    if (environment.production) {
      console.log('startTracking GOOGLE');
      this.gtmService.pushTag({
        event: 'page',
        pageName: page
      });
    }
  }
}
