import {signal, WritableSignal} from '@angular/core';


export class SignalsManager<T> {

  constructor(defaultState: { [key: string]: T }) {
    for (const [key, value] of Object.entries(defaultState)) {
      this.addSignal(key, value);
    }
  }

  signalsCollection = new Map<string, WritableSignal<T>>();

  addSignal(key: string, payload: T) {
    if (this.signalsCollection.has(key)) {
     console.log(`Signal  ${key} ya existe`);
    } else {
      const signalObject = signal<T>(payload);
      this.signalsCollection.set(key, signalObject);
    }
  }

  getSignal(key: string) {
    if (!this.signalsCollection.has(key)) {
      console.log(`Signal  ${key} no existe`);
    }
    // console.log(this.signalsCollection);
    return this.signalsCollection.get(key);
  }

  updateSignal(key: string, payload: T) {
    if (this.signalsCollection.has(key)) {
      const foundSignal = this.getSignal(key);
      foundSignal.set(payload);
    } else {
      this.addSignal(key, payload);
    }
    // console.log(this.signalsCollection);
  }

}
