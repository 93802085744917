import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  openedDialog: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  openDialog(data) {
    console.log(data);
    this.openedDialog.emit(data);
  }

  closeDialog() {
    this.openedDialog.emit(null);
  }

}
