import {Injectable} from '@angular/core';
import {CurrencyPipe, DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  constructor(private datePipe: DatePipe,
              private currencyPipe: CurrencyPipe) {}

// formats
  formatDate(d: Date | string) {
    return this.datePipe.transform(d, 'dd MMMM YYYY');
  }

  cleanCurrency(e: string | number) {
    const str = (e) ? ('' + e) : '0';
    const cleanStr = str.replace(/[^0-9,\-]+/g, '');
    const oneComma = cleanStr.replace(/,(?=[^,]*,)/g, '');
    return +oneComma.replace(',', '.');
  }

  cleanNumber(e: string | number) {
    let cleaned = ('' + e).replace(/[^0-9\-.,]/g, '');
    if (cleaned.indexOf(',') > 0) {
      cleaned = cleaned.replace('.', '');
    }
    return +(cleaned.replace(',', '.'));
  }

  formatPrice(p: number, dec: string = '1.0-0') {
    const v = this.currencyPipe.transform(p, 'EUR', 'symbol-narrow', dec, 'es');
    return v;
  }
}

