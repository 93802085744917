import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {

  private httpClient = inject(HttpClient);

  postFile<T>(p: string, file: File) {
    const token = DataService.getToken();
    const imgEnv = (environment.production) ? 'horus' : 'dev';
    const path = environment.urls.apidomain + '/statics/store/' + p.replace('DEVORPROD', imgEnv);
    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain, */*')
      .append('Charalanda', token)
      .append('X-APP-ENV', imgEnv);
    const formData = new FormData();
    console.log(file);
    formData.append('file', file, file.name);
    return this.httpClient.post<T>(path, formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        let result: any = 0;
        console.log(event, HttpEventType);
        switch (event.type) {
          case HttpEventType.UploadProgress:
            // Calcula y devuelve el porcentaje del progreso
            if (event.total) {
              result = Math.round(100 * event.loaded / event.total);
            }
            break;
          case HttpEventType.Response:
            // Devuelve la respuesta completa cuando se completa la carga
            result = event.body;
            break;
          default:
            // Devuelve un progreso de 0 para cualquier otro evento
            result = 0;
        }
        return result;
      })
    );
  }

}

