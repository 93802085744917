import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RolesService {


  static setRole(r = null) {
    const role = (r) ? r : RolesService.getUser?.credencial.arrRol[0];
    const str = JSON.stringify(role);
    sessionStorage.setItem('[horus]_role', str);
    return true;
  }

  static get getRole() {
    const role = sessionStorage.getItem('[horus]_role');
    return (role) ? JSON.parse(role) : null;
  }

  static get getRoleNameId() {
    const role = RolesService.getRole;
    return (role && role.caption) ? role.nombre.toLowerCase() : null;
  }

  static get getRoleCode() {
    const role = RolesService.getRole;
    return (role) ? role.code : '';
  }

  static get getUser() {
    const user = sessionStorage.getItem('[horus]_user');
    return (user) ? JSON.parse(user).usuario : null;
  }

  static get isAgent() {
    return this.checkRole('agen');
  }
  static get isTelemarketer() {
    return this.checkRole('tele');
  }
  static get isCoordination() {
    return this.checkRole('coor');
  }
  static get isLegal() {
    return this.checkRole('lega');
  }
  static get isTeamAdm() {
    return this.checkRole('equi');
  }
  static get isPartnerAdm() {
    return this.checkRole('soci');
  }
  static get isADM() {
    return this.checkRole('admi');
  }


  static get isManager() {
    const role = RolesService.getRole || null;
    return [ 'equi', 'soci', 'admi' ].includes(role.code);
  }

  static get isPartnerManager() {
    const role = RolesService.getRole || null;
    return [ 'soci', 'admi' ].includes(role.code);
  }

  static checkRole(rol: string) {
    const role = RolesService.getRole;
    let roleMatched = false;
    if (role) {
      roleMatched = (role.code === rol);
    }
    return roleMatched;
  }


  static minorRole(roles: Array<any>) {
    const roleList = [...roles];
    const role = RolesService.getRole;
    if (role) {
      for ( const r of roles) {
        roleList.splice(0, 1);
        if (r.code === role.code) {
          break;
        }
      }
    }
    return roleList;
  }


}
