import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  private readonly domain = environment.urls.apidomain;
  private httpClient = inject(HttpClient);


  static getDUser() {
    const u = sessionStorage.getItem('[horus]_user');
    return JSON.parse(u);
  }

  static getToken() {
    const user = DataService.getDUser();
    return 'horus ' + user?.token;
  }


  static buildHeader(type: number | string) {
    const tk = DataService.getToken();
    let headers: HttpHeaders;
    switch (type) {
      case 1:
        headers = new HttpHeaders().set('Accept', 'application/json, text/plain')
          .append('Content-Type', 'application/json;charset=UTF-8');
        break;
      case 2:
        headers = new HttpHeaders().set('Accept', 'application/json')
          .append('Charalanda', tk);
        break;
      case 3:
        headers = new HttpHeaders().set('Accept', 'application/json, text/plain')
          .append('Content-Type', 'application/json;charset=UTF-8')
          .append('Charalanda', tk);
        break;
      case 4:
        headers = new HttpHeaders().set('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8')
          .append('Content-Type', 'application/x-www-form-urlencoded')
          .append('Charalanda', tk);
        break;
      case 5:
        headers = new HttpHeaders().set('responseType', 'text')
          .append('Content-Type', 'text/html')
          .append('Accept', 'text/html');
        break;
      case 6:
        headers = new HttpHeaders().set('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8')
          .append('Content-Type', 'application/x-www-form-urlencoded')
          .append('Charalanda', tk);
        break;
      default:
        headers = new HttpHeaders().set('responseType', 'text')
          .append('Charalanda', 'horus ' + type as string);
    }
    return headers;
  }


  getExternalData(path: string) {
    return this.httpClient.get<any>(path, {observe: 'body'});
  }

  getData(path: string, type: number | string = 2) {
    const url = this.domain + path;
    const headers = DataService.buildHeader(type);
    return this.httpClient.get<any>(url, {observe: 'body', headers});
  }

  postData(path: string, post: any, type: number = 3) {
    const url = this.domain + path;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(type);
    return this.httpClient.post<any>(url, body, {observe: 'body', headers});
  }

  putData(path: string, post: any) {
    const url = this.domain + path;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    return this.httpClient.put<any>(url, body, {observe: 'body', headers});
  }

  patchData(path: string, post: any) {
    const url = this.domain + path;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    return this.httpClient.patch<any>(url, body, {observe: 'body', headers});
  }

  deleteData(path: string) {
    const fuUrl = this.domain + path;
    const headers = DataService.buildHeader(3);
    return this.httpClient.delete<any>(fuUrl, {observe: 'body', headers});
  }

  get userName() {
    return DataService.getDUser()?.usuario.credencial.username;
  }

  saveUserConf(v: any) {
    const conf = {
      ...this.getUserPref(null),
      ...v
    };
    const vst = JSON.stringify(conf);
    const id = `[horus]UC_$` + this.userName;
    if (!localStorage.getItem(id)){
      localStorage.setItem(id, '');
    }
    localStorage.setItem(id, vst);
  }

  getUserPref(st: string, username: string | null = null) {
    const user = (username) ? username : this.userName;
    const prefs = localStorage.getItem(`[horus]UC_$` + user);
    return (prefs) ? ((st) ? JSON.parse(prefs)[st] : JSON.parse(prefs)) :  null;
  }


}



